import { del, get, HttpResponse, patch, post } from '@/api/Api';
import { api_url } from '@/api/thg/api';
import { TokenType } from '@/enums/TokenType';

import { parseISO } from 'date-fns';
import { THGTag, THGUpdateTagData } from '@/interfaces/thg/THGTag';

/**
 * Retrieve a single Tag.
 *
 * @param id
 * @return http response to await
 */
export async function fetchTHGTag(id: string): Promise<HttpResponse<THGTag>> {
    const res = await get<THGTag>(`${api_url()}/crm/tags/${id}`, TokenType.THG_API_KEY);

    if (res.parsedBody?.created_at) {
        res.parsedBody.created_at = parseISO(res.parsedBody.created_at as string);
    }

    if (res.parsedBody?.updated_at) {
        res.parsedBody.updated_at = parseISO(res.parsedBody.updated_at as string);
    }

    return res;
}

/**
 * Retrieve all Tags.
 *
 * @return list of tags
 */
export async function fetchTHGTags(): Promise<HttpResponse<THGTag[]>> {
    return await get<THGTag[]>(`${api_url()}/crm/tags`, TokenType.THG_API_KEY);
}

/**
 * Add a tag.
 *
 * @param data
 */
export async function addTHGTag(data: THGTag): Promise<HttpResponse<THGTag>> {
    return await post<THGTag>(`${api_url()}/crm/tags`, data, TokenType.THG_API_KEY);
}

/**
 * Update a tag.
 *
 * @param id
 * @param data update data
 */
export async function saveTHGTag(
    id: string,
    data: THGUpdateTagData,
): Promise<HttpResponse<THGTag>> {
    return patch<THGTag>(`${api_url()}/crm/tags/${id}`, data, TokenType.THG_API_KEY);
}

/**
 * Delete a tag.
 *
 * @param id
 * @return http response to await
 */
export async function deleteTHGTag(id: string): Promise<HttpResponse<null>> {
    return await del<null>(`${api_url()}/crm/tags/${id}`, null, TokenType.THG_API_KEY);
}
