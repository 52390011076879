import { del, get, getBlob, HttpResponse, patch, post } from '@/api/Api';
import { TokenType } from '@/enums/TokenType';
import { THGCar, THGCarCreateResponse } from '@/interfaces/thg/THGCar';
import { THGUpdateCarData } from '@/interfaces/thg/THGUpdateCarData';
import { THGCarPaginatedResponse } from '@/interfaces/thg/THGCarPaginatedResponse';
import { THGCarFilterParam } from '@/interfaces/thg/THGCarFilterParam';
import { formatDate } from '@/utils/misc';
import { api_url } from '@/api/thg/api';
import { parseISO } from 'date-fns';
import { SortOrder } from '@/enums/misc';
import { THGCarCountUsersResponseSchema } from '@/interfaces/thg/THGCarCountUsersResponseSchema';

/**
 * Delete a car.
 *
 * @param carId
 * @return http response to await
 */
export async function deleteTHGCar(carId: string): Promise<HttpResponse<null>> {
    return await del<null>(`${api_url()}/crm/cars/${carId}`, null, TokenType.THG_API_KEY);
}

/**
 * Get a thg car by id from thg api.
 *
 * @param carId car ID
 * @return http response to await
 */
export async function getTHGCar(carId: string): Promise<HttpResponse<THGCar>> {
    const res = await get<THGCar>(`${api_url()}/crm/cars/${carId}`, TokenType.THG_API_KEY);

    if (res.parsedBody?.uba_submitted_date) {
        res.parsedBody.uba_submitted_date = parseISO(res.parsedBody.uba_submitted_date as string);
    }

    if (res.parsedBody?.deregistration_date) {
        res.parsedBody.deregistration_date = parseISO(res.parsedBody.deregistration_date as string);
    }

    return res;
}

/**
 *  Fetch all cars belonging to given user.
 *
 * @param userId
 * @param pageSize page size
 * @param pageNum page
 */
export async function fetchTHGUserCars(
    userId: string,
    pageSize: number,
    pageNum: number,
): Promise<HttpResponse<THGCarPaginatedResponse>> {
    const params = new URLSearchParams();

    params.set('page_size', pageSize.toString());
    params.set('page_num', pageNum.toString());
    params.set('user_id', userId);

    return await get<THGCarPaginatedResponse>(
        `${api_url()}/crm/cars?${params}`,
        TokenType.THG_API_KEY,
    );
}

export function buildTHGCarParams(
    filterParams?: THGCarFilterParam,
    sortField?: string,
    sortOrder?: SortOrder,
    pageSize?: number,
    pageNum?: number,
): URLSearchParams {
    const params = new URLSearchParams();

    if (pageSize) {
        params.set('page_size', pageSize.toString());
    }

    if (pageNum) {
        params.set('page_num', pageNum.toString());
    }

    if (sortField) {
        params.set('sort_field', sortField);

        if (sortOrder) {
            params.set('sort_order', sortOrder.toString());
        }
    }

    if (filterParams) {
        if (filterParams.year) {
            params.set('year', filterParams.year.toString());
        }

        if (filterParams.start_create_date && filterParams.end_create_date) {
            const startCreateDateString = formatDate(filterParams.start_create_date, 'yyyy-MM-dd');
            params.set('start_create_date', startCreateDateString);

            const endCreateDateString = formatDate(filterParams.end_create_date, 'yyyy-MM-dd');
            params.set('end_create_date', endCreateDateString);
        }

        if (filterParams.status) {
            params.set('status', filterParams.status);
        }

        if (filterParams.vin) {
            params.set('vin', filterParams.vin);
        }

        if (filterParams.drive_code) {
            params.set('drive_code', filterParams.drive_code);
        }

        if (filterParams.license_plate) {
            params.set('license_plate', filterParams.license_plate);
        }

        if (filterParams.vehicle_class) {
            params.set('vehicle_class', filterParams.vehicle_class);
        }

        if (filterParams.uba_submitted_date) {
            const ubaSubmittedDateString = formatDate(
                filterParams.uba_submitted_date,
                'yyyy-MM-dd',
            );

            params.set('uba_submitted_date', ubaSubmittedDateString);
        }

        if (filterParams.payoff_model) {
            params.set('payoff_model', filterParams.payoff_model);
        }

        if (filterParams.offer_instant_payout) {
            params.set('offer_instant_payout', '1');
        }

        if (filterParams.instant_payout_min) {
            params.set('instant_payout_min', filterParams.instant_payout_min);
        }

        if (filterParams.instant_payout_max) {
            params.set('instant_payout_max', filterParams.instant_payout_max);
        }

        if (filterParams.min_co2_equiv !== undefined && filterParams.min_co2_equiv !== null) {
            params.set('min_co2_equiv', filterParams.min_co2_equiv.toString());
        }

        if (filterParams.max_co2_equiv !== undefined && filterParams.max_co2_equiv !== null) {
            params.set('max_co2_equiv', filterParams.max_co2_equiv.toString());
        }

        if (filterParams.vins.length) {
            if (Array.isArray(filterParams.vins)) {
                params.set('vins', filterParams.vins.join(','));
            } else {
                params.set('vins', filterParams.vins);
            }
        }

        if (filterParams.tags.length) {
            if (Array.isArray(filterParams.tags)) {
                params.set('tags', filterParams.tags.map((tag) => tag._id).join(','));
            } else {
                params.set('tags', filterParams.tags);
            }
        }

        if (filterParams.not_tags.length) {
            if (Array.isArray(filterParams.not_tags)) {
                params.set('not_tags', filterParams.not_tags.map((tag) => tag._id).join(','));
            } else {
                params.set('not_tags', filterParams.not_tags);
            }
        }

        if (filterParams.is_card !== null) {
            params.set('is_card', filterParams.is_card ? '1' : '0');
        }

        if (filterParams.energy_use_measurable !== null) {
            params.set('energy_use_measurable', filterParams.energy_use_measurable ? '1' : '0');
        }

        if (filterParams.creation_source) {
            params.set('creation_source', filterParams.creation_source);
        }
    }

    return params;
}

/**
 * Export the current car list.
 *
 * @param filterParams filter parameters
 * @param sortField sort field
 * @param sortOrder sort oder
 */
export async function exportTHGCars(
    filterParams: THGCarFilterParam,
    sortField?: string,
    sortOrder?: SortOrder,
) {
    const params = buildTHGCarParams(filterParams, sortField, sortOrder);

    return await getBlob(`${api_url()}/crm/cars/export?${params}`, TokenType.THG_API_KEY);
}

/**
 * Export the current car list to the uba Excel file.
 *
 * @param filterParams
 */
export async function exportTHGCarsUBA(filterParams: THGCarFilterParam) {
    const params = buildTHGCarParams(filterParams);

    return await getBlob(`${api_url()}/crm/cars/export-uba?${params}`, TokenType.THG_API_KEY);
}

/**
 * Get the number of users affected by car query.
 *
 * @param filterParams
 */
export async function countAffectedTHGUsers(
    filterParams?: THGCarFilterParam,
): Promise<HttpResponse<THGCarCountUsersResponseSchema>> {
    const params = buildTHGCarParams(filterParams);

    return await get<THGCarCountUsersResponseSchema>(
        `${api_url()}/crm/cars/count/affected-users?${params}`,
        TokenType.THG_API_KEY,
    );
}

/**
 * Fetch all thg vehicles in a paginated way.
 *
 * @param pageNum page
 * @param pageSize page size
 * @param filterParams filter parameters
 * @param sortField sort field
 * @param sortOrder sort oder
 */
export async function fetchTHGCars(
    filterParams?: THGCarFilterParam,
    sortField?: string,
    sortOrder?: SortOrder,
    pageSize?: number,
    pageNum?: number,
): Promise<HttpResponse<THGCarPaginatedResponse>> {
    const params = buildTHGCarParams(filterParams, sortField, sortOrder, pageSize, pageNum);

    return await get<THGCarPaginatedResponse>(
        `${api_url()}/crm/cars?${params}`,
        TokenType.THG_API_KEY,
    );
}

/**
 * Add a THG car.
 *
 * @param payload
 * @param skip_mail_sending
 */
export async function addTHGCar(
    payload: THGCar,
    skip_mail_sending = true,
): Promise<HttpResponse<THGCarCreateResponse>> {
    return await post<THGCarCreateResponse>(
        `${api_url()}/cars?force_payoff_override=${true}&skip_mail_sending=${skip_mail_sending}`,
        payload,
        TokenType.THG_API_KEY,
    );
}

/**
 * Save a THG Car to database.
 *
 * @param carId car uuid as string
 * @param car update data
 */
export async function saveTHGCar(
    carId: string,
    car: THGUpdateCarData,
): Promise<HttpResponse<null>> {
    return await patch<null>(`${api_url()}/cars/${carId}`, car, TokenType.THG_API_KEY);
}
