export enum THGCarPayoffModel {
    FIX = 'fix',
    FLEX = 'flex',
}

export interface THGCarFixPayoffAmount {
    default: number;
}

export interface THGCarFlexPayoffAmount {
    min: number;
    max: number;
}
