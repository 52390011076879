/**
 *  Enum representing a vehicle class.
 */
export enum THGVehicleClass {
    PV = 'pv', // Passenger vehicle, PKW (M1)  # @TODO: migrate to 'm1' value
    M2 = 'm2', // having a maximum mass not exceeding 5 tonnes
    M3 = 'm3', // Bus-like

    LCV = 'lcv', // Light commercial vehicle, LNF (N1) @TODO: migrate to 'n1' value
    N2 = 'n2', // having a maximum mass exceeding 3.5 tonnes but not exceeding 12 tonnes
    N3 = 'n3', // having a maximum mass exceeding 12 tonnes

    L6E = 'l6e', // light quadricycles
    L7E = 'l7e', // heavy quadricycles
    L17 = 'l17', // Light vehicle class 1-7 with 2 or 3 wheels and light four-wheelers
}
