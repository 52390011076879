/**
 *  Enum representing possible car status values.
 */
export enum THGCarStatus {
    PRE_RESERVED = 'pre-reserved', // currently: AT 2024 only
    DATA_RECEIVED = 'data-received',
    REG_DECLINED = 'reg-declined', // currently: AT only
    UBA_SUBMITTED = 'uba-submitted',
    CERT_RECEIVED = 'cert-received',
    INSTANT_PAYOUT_REQUESTED = 'instant-payout-requested',
    CERT_DECLINED = 'cert-declined',
    INCOME_PAID_OUT = 'income-paid-out',
}
